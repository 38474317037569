<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <div class="card" v-if="!transport && !$apollo.loading">
      <div class="card-body text-center">ไม่พบข้อมูล</div>
    </div>
    <b-form
      v-if="transport"
      ref="form"
      class="form"
      @submit.stop.prevent="save"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body">
          <h3 class="text-dark-75 font-weight-bold mb-8">
            รายการพัสดุ
            <span
              v-if="transport.in_order && transport.in_order.length"
              class="text-success"
              >(ฝากสั่ง)</span
            >
            <span v-if="transport.trackingWarehouse" class="text-success"
              >({{ transport.trackingWarehouse.name }})</span
            >
          </h3>
          <div class="row mt-4">
            <div class="col-lg-12 col-6">
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >ขนส่งระหว่างประเทศจีน</label
                >
                <div class="col-lg-5 col-12">
                  <b-dropdown
                    v-model="transport.ship_by"
                    v-if="transport.status === 0"
                    :state="nameState"
                    :text="
                      transport.ship_by === 'ek'
                        ? 'ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)'
                        : 'ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน）'
                    "
                  >
                    <b-dropdown-item
                      value="ek"
                      @click="transport.ship_by = 'ek'"
                    >
                      ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)
                    </b-dropdown-item>
                    <b-dropdown-item
                      value="ship"
                      @click="transport.ship_by = 'ship'"
                    >
                      ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน)
                    </b-dropdown-item>
                  </b-dropdown>
                  <div v-if="transport.status > 0" class="m-desc-lg">
                    {{ transport.ship_by === "ek" ? "รถ" : "เรือ" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6">
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >ประเภทสินค้า</label
                >
                <div class="col-lg-5 col-12">
                  <div class="m-desc-lg">
                    {{
                      transport.userLevelPrice
                        ? transport.userLevelPrice.userLevelDetailName
                        : "รอตรวจสอบ"
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6">
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >ล็อต</label
                >
                <div class="col-lg-5 col-12">
                  <div class="m-desc-lg" v-if="transport.lotId">
                    {{ transport.lotId }}
                  </div>
                  <span
                    v-if="!transport.lotId"
                    class="mt-2 label label-lg label-inline label-light-warning"
                  >
                    ยังไม่มีล็อต
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >ตีลังไม้</label
                >
                <div class="col-lg-5 col-sm-4 col-12">
                  <span class="switch switch-sm mt-1">
                    <label class="d-flex align-items-center">
                      <input
                        type="checkbox"
                        v-model="transport.guarantee"
                        disabled
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6">
              <div class="form-group row mb-2">
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >ค่าตีลัง</label
                >
                <div class="col-lg-5 col-12">
                  <div class="m-desc-lg">
                    {{ transport.price_wooden_box }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6">
              <div
                class="form-group row mb-2"
                v-if="
                  transport.timeline &&
                    transport.timeline.send_to_warehouse_china
                "
              >
                <label
                  for="input-ship_by"
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >เข้าโกดังจีน</label
                >
                <div class="col-lg-5 col-12 m-desc-lg">
                  {{ transport.timeline.send_to_warehouse_china }}
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6">
              <div
                class="form-group row mb-2"
                v-if="transport.timeline && transport.timeline.send_out_china"
              >
                <label
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >ออกโกดัง</label
                >
                <div class="col-lg-5 col-12 m-desc-lg">
                  {{ transport.timeline.send_out_china }}
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6">
              <div
                class="form-group row mb-2"
                v-if="
                  transport.timeline && transport.timeline.send_to_warehouse_th
                "
              >
                <label
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >ถึงโกดังปลายทาง</label
                >
                <div class="col-lg-5 col-12 m-desc-lg">
                  {{ transport.timeline.send_to_warehouse_th }}
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6" v-if="transport.serviceCube">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >ฝากสั่งคิดเพิ่ม คิวละ</label
                >
                <div class="col-lg-5 col-12 m-desc-lg">
                  {{ transport.serviceCube }}
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6" v-if="transport.serviceKg">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >ฝากสั่งคิดเพิ่ม กิโลละ</label
                >
                <div class="col-lg-5 col-12 m-desc-lg">
                  {{ transport.serviceKg }}
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6" v-if="transport.trackingWarehouse">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >โกดัง{{ transport.trackingWarehouse.name }}คิดเพิ่ม
                  คิวละ</label
                >
                <div class="col-lg-5 col-12 m-desc-lg">
                  {{ transport.trackingWarehouse.warehouseCube }}
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6" v-if="transport.trackingWarehouse">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >โกดัง{{ transport.trackingWarehouse.name }}คิดเพิ่ม
                  กิโลละ</label
                >
                <div class="col-lg-5 col-12 m-desc-lg">
                  {{ transport.trackingWarehouse.warehouseKg }}
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6">
              <div class="form-group row mb-2">
                <label
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >รูป</label
                >
                <div
                  class="col-lg-5 col-12 m-desc-lg"
                  v-if="transport.file_tracking"
                >
                  <viewer :images="transport.file_tracking.map(x => x)">
                    <div
                      class="symbol symbol-40 flex-shrink-0 mr-4"
                      v-for="file in transport.file_tracking"
                      :key="file"
                    >
                      <img
                        :src="imageUrlApi(file)"
                        style="object-fit: cover"
                        class="symbol-label"
                      />
                    </div>
                  </viewer>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-6">
              <div class="form-group row mb-2" v-if="transport.payshipping_doc">
                <label
                  class="col-form-label fw-bolder text-lg-right col-lg-3 col-12"
                  >เลขที่บิล</label
                >
                <div class="col-lg-5 col-12 m-desc-lg">
                  {{ transport.payshipping_doc }}
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="table-responsive">
                <table class="table table-head-bg mt-4">
                  <thead>
                    <tr>
                      <th>เลขพัสดุ (จีน)</th>
                      <th class="text-center">จำนวน</th>
                      <th class="text-center">KG/CBM</th>
                      <th class="text-center text-nowrap">ขนาด</th>
                      <th class="text-nowrap">บันทึกช่วยจำ</th>
                      <th class="text-center">ราคา</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b-form-input
                          id="input-transportNumber"
                          v-if="transport.status === 0"
                          v-model="transport.transportNumber"
                          :state="nameState"
                          required
                          placeholder=""
                        ></b-form-input>
                        <div v-if="transport.status > 0">
                          {{ transport.transportNumber }}
                        </div>
                      </td>
                      <td class="text-center">
                        <b-form-input
                          id="amount"
                          type="number"
                          v-if="transport.status === 0"
                          :state="nameState"
                          min="1"
                          required
                          v-model="transport.amount"
                          class="mb-2"
                        ></b-form-input>
                        <div v-if="transport.status > 0">
                          {{ transport.amount }}
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="text-nowrap">
                          KG:
                          {{ " " }}
                          {{
                            calculateWeight(
                              transport.size && transport.size.weight,
                              transport.amount
                            ) || "-"
                          }}
                        </div>
                        <div class="text-nowrap">
                          CBM:
                          {{ " " }}
                          {{
                            calculateCube(transport.size, transport.amount) ||
                              "-"
                          }}
                        </div>
                      </td>
                      <td class="text-center text-nowrap">
                        {{
                          transport.size
                            ? `${transport.size.width || 0} x ${transport.size
                                .long || 0} x ${transport.size.height || 0}`
                            : "0 x 0 x 0"
                        }}
                      </td>
                      <td>
                        <b-form-input
                          id="remark"
                          now-button
                          v-model="transport.remark"
                        ></b-form-input>
                      </td>
                      <td class="text-center text-nowrap">
                        <div v-if="transport.trackingPrice">
                          {{
                            transport.trackingPrice.priceName === "kg"
                              ? "กิโล : "
                              : "ขนาด : "
                          }}
                          {{ format_comma(transport.trackingPrice.priceValue) }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-10 ml-lg-auto">
              <button
                type="submit"
                class="btn btn-success mr-4 mb-2"
                style="min-width: 140px"
              >
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="/media/svg/icons/General/Save.svg" />
                </span>
                บันทึกรายการขนส่ง
              </button>
              <router-link
                to="/forwarder"
                class="btn btn-secondary mb-2"
                style="width: 140px"
              >
                ยกเลิก
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_TRACKING,
  UPDATE_TRACKING,
  DELETE_TRACKING
} from "@/graphql/transport.js";
import { calculateCube, calculateWeight, WEBSITE, imageUrlApi } from "@/config";
import dayjs from "dayjs";
import "dayjs/locale/th";

dayjs.locale("th");

export default {
  mixins: [validationMixin],
  name: "forwarder_detail",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `รายการพัสดุ`, route: "/forwarder" },
      { title: `รายการพัสดุ` }
    ]);
  },
  data() {
    const { id } = this.$route.params;
    document.title = `รายการพัสดุ #${id} | ${WEBSITE}`;
    return {
      nameState: null,
      id: Number(id),
      transport: {
        transportNumber: "",
        amount: 0,
        ship_by: "ek",
        remark: "",
        guarantee: false
      }
    };
  },
  validations: {
    transport: {
      transportNumber: {
        required
      },
      amount: {
        required
      },
      ship_by: {
        required
      },
      remark: {},
      guarantee: {}
    }
  },
  methods: {
    imageUrlApi,
    calculateCube,
    calculateWeight,
    format_comma(val) {
      return Number(val).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    async save() {
      if (!this.checkFormValidity()) {
        return;
      }

      const transportNumber = this.$v.transport.transportNumber.$model;
      const ship_by = this.$v.transport.ship_by.$model;
      const amount = this.$v.transport.amount.$model;
      const remark = this.$v.transport.remark.$model;
      const guarantee = this.$v.transport.guarantee.$model;

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const input = {
        _id: this.transport._id,
        transportId: this.transport.transportId,
        transportNumber,
        ship_by,
        amount: Number(amount),
        remark,
        guarantee
      };
      const {
        data: { updateTransport }
      } = await this.$apollo.mutate({
        mutation: UPDATE_TRACKING,
        variables: {
          input: input
        }
      });
      if (!updateTransport.isSuccess) {
        this.$message.error(updateTransport.message);
      } else {
        this.$message.success(updateTransport.message);
        this.$apollo.queries.transport.refetch();
      }
    },
    async deleteItem(item) {
      const confirm = await Swal.fire({
        title: "ยืนยันลบข้อมูล ?",
        icon: "warning",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }
      const {
        data: { removeTransport }
      } = await this.$apollo.mutate({
        mutation: DELETE_TRACKING,
        variables: {
          id: item.transportId
        }
      });
      if (!removeTransport.isSuccess) {
        this.$message.error(removeTransport.message);
      } else {
        this.$message.success(removeTransport.message);
        this.$router.push("/forwarder");
      }
    }
  },
  apollo: {
    transport: {
      query: GET_TRACKING,
      variables() {
        return {
          id: this.id
        };
      },
      update: ({ transport }) => {
        if (!transport) {
          return transport;
        }
        if (transport.timeline) {
          const {
            send_to_warehouse_china,
            send_out_china,
            send_to_warehouse_th
          } = transport.timeline;
          if (send_to_warehouse_china) {
            transport.timeline.send_to_warehouse_china = dayjs(
              send_to_warehouse_china
            ).format("DD MMMM YYYY");
          }
          if (send_out_china) {
            transport.timeline.send_out_china = dayjs(send_out_china).format(
              "DD MMMM YYYY"
            );
          }
          if (send_to_warehouse_th) {
            transport.timeline.send_to_warehouse_th = dayjs(
              send_to_warehouse_th
            ).format("DD MMMM YYYY");
          }
        }
        return transport;
      }
    }
  }
};
</script>
